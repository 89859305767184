import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section
        className="tf__banner banner"
        style={{ backgroundImage: "url(images/bg/banner.jpg)" }}
      >
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-6 col-lg-8">
              <div className="tf__banner_text">
                <h1>
                  <span className="text-white">
                    {" "}
                    Where your financial dreams
                  </span>
                  <span className="cd-headline rotate-1">
                    <span
                      className="cd-words-wrapper"
                      style={{ width: "346.65px;" }}
                    >
                      <b className="is-visible">become reality</b>
                    </span>
                  </span>
                </h1>
                <p>
                  And In Order To Make A Business, Brand Advertising And
                  Marketing Plays An Important Role. Similarly, In Making
                  Cultivation Business A Brand, Good Slogans Are Necessary.
                </p>
                <ul className="p-0 m-0">
                  <li>
                    <Link className="common_btn" to="#">
                      Get Started
                      <i className="fa-solid fa-arrow-down-to-line"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4">
              <div className="tf__banner_img">
                <div className="img">
                  <img
                    src="images/banner_img_1.png"
                    alt="ZYAN"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
