import React from "react";
import Contact from "../components/Contact";
import Breadcrumb from "../components/Breadcrumb";

const ContactUs = () => {
  return (
    <>
      <Breadcrumb title="Contact" />
      <Contact />
    </>
  );
};

export default ContactUs;
