import React from "react";
import Headin from "./Headin";

const ServicePagesSection = (props) => {
  return (
    <>
      <section className="tf__about pt_140 xs_pt_80 mb-5" id="about">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xxl-5 col-lg-6 col-xl-6">
              <Headin subtitle={props.subtitle} />
              <div className="">
                <p className="mb-5">{props.details}</p>
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6 col-xl-6">
              <div
                className="tf__about_img fade_right anim"
                data-trigerid="about"
              >
                <img src={props.img} alt="" className="img-fluid w-100" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf__about pt_10 xs_pt_10 mb-5" id="about">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xxl-5 col-lg-6 col-xl-6">
              <div
                className="tf__about_img fade_right anim"
                data-trigerid="about"
              >
                <img src={props.img2} alt="" className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6 col-xl-6">
              <Headin subtitle={props.subtitle2} />
              <div className="">
                <p className="mb-5">{props.details2}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicePagesSection;
