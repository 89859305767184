import React from "react";

const ServiceCard = (props) => {
  return (
    <>
      <div className="col-lg-5 col-md-6">
        <div className="tf__single_service fade_left anim">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8 ">
              <h3 className="text-gold text-left mt-3">{props.title}</h3>
              <p className="text-white text-left">{props.subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
