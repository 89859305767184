import "./App.css";
import Layout from "./pages/Layout";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/Pricing";
import Recharge from "./pages/Recharge";
import BillPayment from "./pages/BillPayment";
import { Route, Routes, Navigate } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<AboutUs />} />
          <Route path="/contact" exact element={<ContactUs />} />
          <Route path="/recharge" exact element={<Recharge />} />
          <Route path="/pricing" exact element={<Pricing />} />
          <Route path="/bill_payment" exact element={<BillPayment />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
