import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServicePagesSection from "../components/ServicePagesSection";

const Recharge = () => {
  return (
    <>
      <Breadcrumb title="Recharge" />
      <ServicePagesSection
        subtitle="Paying bills has never been easier."
        img="images/3.jpg"
        subtitle2="All-in-One Bill Payment platform for businesses."
        details="With our Bill Payments feature, you can settle your utility bills, credit card payments, and more, all in one place."
        img2="images/4.jpg"
        details2="Discover the ultimate all-in-one bill payment platform for businesses. Streamline transactions, enhance efficiency, and boost financial convenience."
      />
    </>
  );
};

export default Recharge;
