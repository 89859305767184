import React from "react";
import Headin from "./Headin";
import ServiceCard from "./ServiceCard";

const data = [
  {
    title: "Recharge ",
    subtitle:
      "Trnxact is provides prepaid mobile recharge and other recharge services in India.",
  },
  {
    title: "Bill Payment",
    subtitle:
      "Trnxact is another excellent payment solution for online payments in India.",
  },
];

const ServiceSection = () => {
  return (
    <>
      <section className="tf__service pt_130 xs_pt_80" id="service">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto text-center">
              <Headin
                title="MY SERVICE"
                subtitle="Trnxact Products."
                description="Trnxact offer various products which is being offered by traditional payment gateways but directly with Bank"
              />
            </div>
          </div>
          <div className="row justify-content-center">
            {data.map((datas, index) => (
              <ServiceCard
                title={datas.title}
                subtitle={datas.subtitle}
                key={index}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSection;
