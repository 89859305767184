import React from "react";
import Hero from "../components/Hero";
import ServiceSection from "../components/ServiceSection";
import WorkProcess from "../components/WorkProcess";
import AboutSection from "../components/AboutSection";
import Subscribe from "../components/Subscribe";
import Faqsection from "../components/Faqsection";

const Home = () => {
  return (
    <>
      <Hero />
      <ServiceSection />
      <AboutSection />
      <WorkProcess />
      <Faqsection />
      <Subscribe />
    </>
  );
};

export default Home;
