import React from "react";
import Headin from "./Headin";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <>
      <section className="tf__about pt_140 xs_pt_80 mb-5" id="about">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xxl-7 col-lg-6 col-xl-6">
              <Headin
                title="ABOUT ME"
                subtitle="Trnxact is a company specializing in payment solutions"
              />
              <div className="tf__about_text">
                <p className="mb-5">
                  Trnxact is a payment intermediary that offers comprehensive
                  payment solutions. This includes services of a few: Bill Pay
                  and Recharge .
                </p>
                {/* <div className="col-md-12 mb-5">
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="pl-0">
                        <li>Bill Payment</li>
                        <li>Bill Payment</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="">
                        <li>Bill Payment</li>
                        <li>Bill Payment</li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/*  */}
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6 col-xl-6">
              <div className="fade_right anim" data-trigerid="about">
                <img
                  src="images/about.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
